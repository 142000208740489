<template>
  <div>
    <v-select
      dense
      outlined
      :items="discountTypeOptions"
      v-model="formData.discount_type"
    ></v-select>

    <div v-show="formData.discount_type == 1">
      <discount-config-item
        v-for="(config, index) in data"
        :key="index"
        :unit="unit"
        :index="index"
        v-model="data"
        @deleteItem="deleteItem"
      />
      <div class="add_btn_content" @click="createItem">
        <v-btn class="btn btn_add">
          <div class="add_icon">
            <i
              aria-hidden="true"
              class="v-icon notranslate ri ri-add-line theme--light"
            ></i>
          </div>
          <div class="add_title">新增量多優惠區間</div>
        </v-btn>
      </div>
    </div>

    <div v-show="formData.discount_type == 2">
      <div v-show="vipPriceData.priceList.length > 0" class="main_content">
        <table density="comfortable" key="vipTable">
          <thead>
            <tr>
              <th class="text-center"></th>
              <th class="text-center">客戶分級</th>
              <th class="text-center">優惠單價</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="opt in vipPriceData.priceList" :key="opt.id">
              <td>
                <v-checkbox
                  v-model="vipPriceData.selectedList"
                  :value="opt.id"
                  hide-details="auto"
                >
                </v-checkbox>
              </td>
              <td>{{ opt.name }}</td>
              <td>
                <v-text-field
                  v-model="opt.price"
                  outlined
                  hide-details
                  @keyup="checkInputValid(opt)"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="add_btn_content" @click="addNewVipRule">
          <v-btn class="btn btn_add">
            <div class="add_icon">
              <i
                aria-hidden="true"
                class="v-icon notranslate ri ri-add-line theme--light"
              ></i>
            </div>
            <div class="add_title">新增客戶分級</div>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formItemMixin from "@/components/form/mixins/formItemMixin.js";
import discountConfigItem from "./discountConfigItem.vue";
export default {
  mixins: [formItemMixin],
  components: {
    discountConfigItem,
  },
  data: () => ({
    data: [],
    vipPriceData: {
      priceList: [
        // {
        //     "name": "黃金客戶",
        //     "price": 100,
        //     id: 123,
        //     isSelected: false
        // },
        // {
        //     "name": "一般客戶",
        //     "price": 200,
        //     id: 456,
        //     isSelected: false
        // }
      ],
      selectedList: [],
    },
  }),
  computed: {
    providerId() {
      return this.$store.getters[`member/providerId`];
    },
    discountTypeOptions() {
      return [
        { text: "無優惠", value: 0 },
        { text: "量多優惠", value: 1 },
        { text: "客戶等級優惠", value: 2 },
      ];
    },
    discountConfigs() {
      return this.formData.discount_config;
    },
    unit() {
      return this.formData.shipping_unit;
    },
    vipList() {
      return this.formData.vipList;
    },
    vipPriceList() {
      return this.formData.vipPriceList;
    },
  },
  watch: {
    "vipPriceData.selectedList": {
      deep: true,
      immediate: true,
      handler() {
        this.vipPriceData.priceList.forEach((v) => {
          v.isSelected = this.vipPriceData.selectedList.includes(v.id);
        });
      },
    },
    "vipPriceData.priceList": {
      deep: true,
      immediate: true,
      handler() {
        this.formData.vipList = this.vipPriceData.priceList;
      },
    },
    discountConfigs: {
      deep: true,
      immediate: true,
      handler() {
        this.data = this.$eagleLodash.cloneDeep(this.discountConfigs) || [];
        this.formData.vipList = this.vipPriceData.priceList;
      },
    },
    data: {
      deep: true,
      handler() {
        if (this.$eagleLodash.isEqual(this.data, this.discountConfigs)) return;
        this.sync();
      },
    },
  },
  async mounted() {
    this.fetchVipData();
  },
  methods: {
    addNewVipRule() {
      this.$apopup.prompt({
        title: "新增客戶分級",
        disabledApply: (data) => !data,
        promptMaxLength: 7,
        applyCallback: async (data) => {
          this.$root.gaLogEvent("供應商_點擊_建立客戶分級");
          const result = await this.$api.collection.vipApi.add(
            this.providerId,
            { name: data }
          );
          this.$snotify.success("新增成功");
          this.fetchVipData();
        },
      });
    },
    async fetchVipData() {
      const vipData = await this.$api.collection.vipApi.read(this.providerId);
      this.vipPriceData.priceList = vipData.map((vipAll) => {
        let returnObj = vipAll;
        returnObj.isSelected = false;
        returnObj.price = "";
        if (this.vipPriceList) {
          this.vipPriceList.forEach((vipPriced) => {
            if (vipAll.name === vipPriced.name) {
              returnObj.price = vipPriced.price;
              returnObj.isSelected = Boolean(vipPriced.status);
            }
          });
        }
        return returnObj;
      });
      this.vipPriceData.selectedList = this.vipPriceData.priceList
        .filter((v) => v.isSelected)
        .map((v) => v.id);
    },
    checkInputValid(opt) {
      this.$set(opt, "price", opt.price.replace(/[^\d.-]+/g, ""));
    },
    createItem() {
      this.data.push({
        min: 0,
        max: 0,
        price: 0,
      });
    },
    deleteItem(index) {
      this.data.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.add_btn_content {
  width: 100%;
  margin: 5px 0;
  .btn_add {
    width: 100%;
    border: 1px solid #ff7d00;
    background: #fff !important;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 67px;
    box-shadow: none;

    .add_icon {
      .theme--light.v-icon {
        font-size: 25px;
        color: #ff7d00;
      }
    }

    .add_title {
      font-size: 16px;
      color: #ff7d00;
    }
  }

  .add_btn_box {
    width: 100%;
    border: 1px solid #ff7d00;
    background: #fff;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
.main_content {
  border: 1px solid #f1f1f1;
  padding: 10px;
  th {
    font-size: 13px;
    font-weight: normal;
    padding-bottom: 3px;
    color: rgba(0, 0, 0, 0.87);
  }
  td {
    font-size: 13px;
    padding: 10px 10px;
    color: rgba(0, 0, 0, 0.87);
    .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }
  }
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.87);
}
</style>